@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
  .btn-primary {
    @apply mx-auto lg:mx-0 bg-mnb-primary hover:bg-white font-bold font-display text-xl text-mnb-secondary border-2 border-mnb-secondary rounded-md py-2 px-6;
  }
}

@layer base {
  @font-face {
    font-family: "Bespoke Serif";
    font-weight: 300;
    src: url(/fonts/BespokeSerif-Regular.woff2) format("woff2"), url(/fonts/BespokeSerif-Regular.woff) format("woff")
  }
  @font-face {
    font-family: "Bespoke Serif";
    font-weight: 500;
    src: url(/fonts/BespokeSerif-Medium.woff2) format("woff2"), url(/fonts/BespokeSerif-Medium.woff) format("woff")
  }
  @font-face {
    font-family: "Bespoke Serif";
    font-weight: 500;
    font-style: italic;
    src: url(/fonts/BespokeSerif-MediumItalic.woff2) format("woff2"), url(/fonts/BespokeSerif-MediumItalic.woff) format("woff")
  }
  @font-face {
    font-family: "Bespoke Serif";
    font-weight: 700;
    src: url(/fonts/BespokeSerif-Bold.woff2) format("woff2"), url(/fonts/BespokeSerif-Bold.woff) format("woff")
  }
  @font-face {
    font-family: "Supreme";
    font-weight: 300;
    src: url(/fonts/Supreme-Light.woff2) format("woff2"), url(/fonts/Supreme-Light.woff) format("woff")
  }
  @font-face {
    font-family: "Supreme";
    font-weight: 500;
    src: url(/fonts/Supreme-Medium.woff2) format("woff2"), url(/fonts/Supreme-Medium.woff) format("woff")
  }
  @font-face {
    font-family: "Supreme";
    font-weight: 500;
    font-style: italic;
    src: url(/fonts/Supreme-MediumItalic.woff2) format("woff2"), url(/fonts/Supreme-MediumItalic.woff) format("woff")
  }
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

[data-tooltip-arrow]::after {
  content: " ";
  position: absolute;
  top: 5px;
  left: -1rem;
  transform:rotate(90deg);
  border-width: 0.5rem;
  border-color: white transparent transparent transparent;
}

[data-tooltip-arrow-grey]::after {
  content: " ";
  position: absolute;
  top: 5px;
  left: -1rem;
  transform:rotate(90deg);
  border-width: 0.5rem;
  border-color: #E5E7EB transparent transparent transparent;
}

.speech-bubble:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #333333;
  right: 75%;
  top: -21px;
}

.speech-bubble:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fff;
  right: 75%;
  top: -19px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.ribbon{
  border-top: 0;
  border-bottom: 20px solid transparent;
  height: 60px;
  width: 2px;
}

.gallery-item {
  scroll-snap-align: center;
}

.gallery {
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

ol.terms {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol.terms > li {
  counter-increment: item;
  display: table;
  margin: 1rem 0;
  padding: 0;
}

ol.terms > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 1rem;
}

ol.terms > li li:before {
    content: counters(item, ".") " ";
}

ol.terms > li li li:before {
    content: counter(item, lower-roman) ". ";
}
